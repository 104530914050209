<template>
  <div class="mainCtn">
    <h1>Informar de un problema</h1>
    <div>
      <b>Por favor, indicanos tu sugerencia o incidencia:</b>
      <textarea class="problemAnswer" placeholder="Escribe aquí" v-model="problem" rows="5" />
    </div>
    <vs-button block type="filled">Enviar</vs-button>
  </div>
</template>

<script>
export default {
  name: "Problems",
  data() {
    return {
      problem: ""
    };
  }
};
</script>

<style scoped>
.mainCtn {
  padding: 1rem;
}

.problemAnswer {
  border: 5px solid var(--main-byp);
  border-radius: 1rem;
  width: 100%;
}
</style>
